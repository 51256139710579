// This must be the first line in src/index.js
//ie polyfill
import 'babel-polyfill';
import 'react-app-polyfill/ie9';

import React from 'react';
import ReactDOM from 'react-dom';
// import Loadable from 'react-loadable';
//css reset
import 'normalize.css/normalize.css';
import 'antd/dist/antd.css';
import './index.scss';
import App from './components/App';

// import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
