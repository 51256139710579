import React from 'react';
import Loadable from 'react-loadable';
import {Spin} from 'antd';

function loading() {
    return <div className="page_loading"><Spin size={'large'}/></div>;
}

const Home = Loadable({
    loader: () => import('../components/Home'),
    loading,
});
const Life = Loadable({
    loader: () => import('../components/Life'),
    loading,
});
const Intro = Loadable({
    loader: () => import('../components/Intro'),
    loading,
});
const Work = Loadable({
    loader: () => import('../components/Work'),
    loading,
});
const WorkDetail = Loadable({
    loader: () => import('../components/WorkDetail'),
    loading,
});
const routes = [
    {
        path: '/',
        component: Home,
        exact: true,
    },
    {
        path: '/work',
        component: Work,
    },
    {
        path: '/work-detail',
        component: WorkDetail,
    },
    {
        path: '/life',
        component: Life,
    },
    {
        path: '/intro',
        component: Intro,
    }
];
export default routes;