import React, {Component} from 'react';
import {HashRouter as Router, Route} from "react-router-dom";
import LeftNav from '../LeftNav';
import routes from '../../route';
// import Animation from '../Animation'
import './index.scss';

export default class App extends Component {
    state = {
        activeMenuIndex: 0,
    }
    onMenuClick = (index) => {
        this.setState({
            activeMenuIndex: index,
        })
    }

    render() {
        const {activeMenuIndex} = this.state;
        return (
            <Router>
                <div className="screen">
                    <div className="app">
                        {/*<Animation/>*/}
                        <LeftNav onMenuClick={this.onMenuClick} activeMenuIndex={activeMenuIndex}/>
                        <div className="content">
                            {routes.map(r => (
                                <Route {...r} key={r.path}/>
                            ))}
                        </div>
                    </div>
                </div>
            </Router>
        );
    }
}

