import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
// import classnames from 'classnames';
import './index.scss';

export default class LeftMenu extends Component {
    static defaultProps = {
        activeMenuIndex: 0
    }
    state = {
        menuList: [
            {
                label: '首页',
                enLabel: 'HOME',
                path: '/',
                exact: true,
            },
            {
                label: '作品',
                enLabel: 'WORKS',
                path: '/work',
            },
            // {
            //     label: '文创产品',
            //     enLabel: 'PRODUCTS',
            //     path: '/prod',
            // },
            // {
            //     label: '资讯',
            //     enLabel: 'INFORMATION',
            //     path: '/info',
            // },
            {
                label: '生平',
                enLabel: 'ALL ONE\'S LIFE',
                path: '/life'
            },
            {
                label: '博物馆介绍',
                enLabel: 'INTRODUCTION',
                path: '/intro',
            },
        ]
    };

    render() {
        // const {activeMenuIndex, onMenuClick} = this.props;
        const {menuList,} = this.state;
        return (
            <ul className="left_menu">
                {menuList.map((menu, index) => (
                    <li key={menu.label}
                        // className={classnames({active: activeMenuIndex === index})}
                        // onClick={() => {
                        //     onMenuClick(index);
                        // }}
                    >
                        <NavLink to={menu.path} activeClassName="active" className="menu_nav_link" exact={menu.exact}>
                            <div className="label">{menu.label}</div>
                            <div className="en_label">{menu.enLabel}</div>
                            <span className="border_dot"/>
                        </NavLink>
                    </li>))}
            </ul>
        );
    }
}